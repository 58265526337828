<template>
     <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0" v-if="display">
        <div class="faq-item" @click="toggleQuestion()">
            <div class="title-wrapper">
                <h4 class="title" :class="{ active: showAnswer }">{{ title }}</h4>
            </div>
            <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <div class="text" v-show="showAnswer" v-html="text">
                    
                </div>
            </transition>
            <div class="icon" :class="{ active: showAnswer }">
                <font-awesome-icon icon="chevron-down" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaqItem',
    props: {
        title: String,
        text: String,
        display: Boolean
    },
    data() {
        return {
            showAnswer: false
        }
    },

    methods: {
        toggleQuestion() {
            this.showAnswer = !this.showAnswer;
        },

        enter(el) {
            el.style.height = 'auto';
            const height = getComputedStyle(el).height;
            el.style.height = 0;
            getComputedStyle(el);
            setTimeout(() => {
                el.style.height = height;
            });        
        },

        afterEnter(el) {
            el.style.height = 'auto';
        },

        leave(el) {
            el.style.height = getComputedStyle(el).height;
            getComputedStyle(el);
            setTimeout(() => {
                el.style.height = 0;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.faq-item {
    background-color: $white;
    padding: 2.5rem;
    border-radius: 8px;
    box-shadow: $shadow-1;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    
    .title {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: normal;
        text-transform: none;
        margin: 0;
        user-select: none;

        &.active {
            font-weight: bold;
        }

        &-wrapper {
            padding-right: 5rem;
        }
    }

    .icon {
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
        width: 4rem;
        height: 4rem;
        border: 2px solid $grey-light-1;
        border-radius: 50%;
        text-align: center;
        line-height: 4.1rem;
        font-size: 1.8rem;
        background: $grey-light-1;
        color: $primary-color;
        transform: rotate(0deg);
        transition: all .2s ease;

        &.active {
            transform: rotate(-180deg);
        }
    }

    .text {
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 300;
        padding-top: 2rem;
        padding-bottom: 1rem;
        padding-right: 4rem;
    }
}

.expand-enter-active, .expand-leave-active {
    transition: height .25s ease-in-out;
    overflow: hidden;
}
</style>